import React, {useEffect, useState, useContext} from 'react'
import Main from '../components/Main'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useHistory } from 'react-router-dom'

import {notification, Input, Form, Select, Switch, Button, Modal } from 'antd'
import { UserAddOutlined , LoadingOutlined  } from '@ant-design/icons'

import { GeneralContext } from '../lib/context'
import { isMultiStore, clienStoreId, UI_COLOR_BUTTON } from '../config'
import {
  REST_LOCATIONS,
  REST_CUSTOMER_REGISTER,
  REST_GET_ALLCOUNTRIES,
  getUrlHashtag,
  storeLocalData,
  requestToServer,
} from '../lib/helper'

export default function Register() {

  let history = useHistory()
  const [state] = useContext(GeneralContext)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [countryId, setCountryId] = useState('')
  const [locationId, setLocationId] = useState('')
  const [readPolicy, setReadPolicy] = useState(false)

  const [countries, setCountries] = useState([])
  const [locations, setLocations] = useState([])

  const [showPrivacy, setShowPrivacy] = useState(false)

  const [backendWorking, setBackendWorking] = useState(false)

  useEffect( () => {

    requestToServer({},REST_GET_ALLCOUNTRIES).then( ({countries}) => {
      setCountries(countries)
      setCountryId(countries[0]['id'])
    })

    requestToServer( {}, REST_LOCATIONS ).then( ({ locations }) => {
      if ( locations && locations.length > 0 ) {
        setLocations(locations)
        setLocationId( locations[0]['id'] )
      }
    })

    if ( state.socialLogin ) { // comes from login page
      let name = state.socialLogin.additionalUserInfo && state.socialLogin.additionalUserInfo.profile && state.socialLogin.additionalUserInfo.profile.given_name ? state.socialLogin.additionalUserInfo.profile.given_name : ''
      setFirstName( name )
      let lastName = state.socialLogin.additionalUserInfo && state.socialLogin.additionalUserInfo.profile && state.socialLogin.additionalUserInfo.profile.family_name ? state.socialLogin.additionalUserInfo.profile.family_name : ''
      setLastName(lastName)
      let userEmail = state.socialLogin.additionalUserInfo && state.socialLogin.additionalUserInfo.profile && state.socialLogin.additionalUserInfo.profile.email ? state.socialLogin.additionalUserInfo.profile.email : ''
      setEmail(userEmail)
    }

  }, [])

  const doRegistration = () => {
    if ( !backendWorking ) {
      setBackendWorking(true)

      let hashTag = getUrlHashtag() // hashTag = storeId_customerRefererId

      if (readPolicy) {
        if (firstName, email ) {
          if ( state.socialLogin ) {
            let socialMediaLogin = {}
            if ( state.socialLogin.additionalUserInfo.providerId === 'google.com') {
              socialMediaLogin.media = 'google'
              socialMediaLogin.idToken = state.socialLogin.credential.idToken
            }
            if ( state.socialLogin.additionalUserInfo.providerId === 'facebook.com') {
              socialMediaLogin.media = 'facebook'
              socialMediaLogin.accessToken = state.socialLogin.credential.accessToken
            }
            let customerData = { firstName, lastName, countryId, socialMediaLogin, locationId }
            if (hashTag) { customerData.hashTag = hashTag}
            if ( !isMultiStore ) { customerData.storeId = clienStoreId }
            requestToServer(customerData, REST_CUSTOMER_REGISTER).then( result => {
              if (result.s === 'ok') {
                notification.success({
                  message: 'Registro concluido',
                })
                setBackendWorking(false)
                storeLocalData(result)
                history.push('/')
              } else {
                notification.error({
                  message: 'No se puede registrar',
                  description: result.m
                })
                setBackendWorking(false)
              }
            })
          } else { // standard registration with email
            if ( password && password2 && password === password2 ) {
              let customerData = { email, firstName, lastName, countryId, password, locationId }
              if (hashTag) { customerData.hashTag = hashTag}
              if ( !isMultiStore ) { customerData.storeId = clienStoreId }
              requestToServer(customerData, REST_CUSTOMER_REGISTER).then( result => {
                if (result.s === 'ok') {
                  notification.success({
                    message: 'Registro concluido',
                  })
                  setBackendWorking(false)
                  storeLocalData(result)
                  history.push('/')
                } else {
                  notification.info({
                    message: result.m,
                    duration: null
                  })
                  setBackendWorking(false)
                }
              })
            } else {
              notification.error({
                message: 'No se puede registrar',
                description: 'Las contraseñas no coinciden'
              })
              setBackendWorking(false)
            }
          }
        } else {
          notification.error({
            message: 'No se puede registrar',
            description: 'Tiene que ingresar su Nombre y Email'
          })
          setBackendWorking(false)
        }
      } else {
        notification.error({
          message: 'No se puede registrar',
          description: 'Tiene que leer nuestra Política de Privacidad'
        })
        setBackendWorking(false)
      }
    }
  }

  return (
    <Main>
      <Header title="Registro" backLink="/" />
      <Form style={{paddingLeft:'15px', paddingRight:'15px', maxWidth:'400px', margin:'0 auto', paddingBottom:'20px' }}>
        <Form.Item label="Nombres" >
          <Input  value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
        </Form.Item>

        <Form.Item label="Apellidos" >
          <Input value={lastName} onChange={(e)=>setLastName(e.target.value)} />
        </Form.Item>

        <Form.Item label="Email" >
          <Input label="Email" value={email} onChange={(e)=>setEmail(e.target.value)} type='email' disabled={ state.socialLogin ? true : false } />
        </Form.Item>

        { ! state.socialLogin && <div>

          <Form.Item label="Contraseña" >
            <Input.Password value={password} onChange={(e)=>setPassword(e.target.value)}/>
          </Form.Item>
          <Form.Item label="Repetir Contraseña" >
            <Input.Password value={password2} onChange={(e)=>setPassword2(e.target.value)}/>
          </Form.Item>

        </div>}

        { countries && countries.length > 1 && <div>
          <Form.Item label="País" >
            <Select style={{width:'178px'}} value={countryId} onChange={(e)=>setCountryId(e)}>
              { countries.map(item=>{ return(
                <Select.Option value={item.id} key={'country' + item.id}>{item.name}</Select.Option>
              )}) }
            </Select>
          </Form.Item>

        </div>}

        <Form.Item label="Dónde te registras" >
          <Select style={{ width: '100%' }} value={locationId} onChange={(e)=>setLocationId(e)}>
            { locations.map( location => { 
              return(
                <Select.Option value={location.id} key={'location_' + location.id}>{location.name}</Select.Option>
              )
            }) }
          </Select>
        </Form.Item>

        <div>
          <Switch style={{marginRight:'5px'}}
            checked={readPolicy}
            onChange={(e)=>setReadPolicy(e)}
          /> He Leído la <a href="#" onClick={(e)=>{
            e.preventDefault()
            setShowPrivacy(true)
          }}>Política de Privacidad</a>
        </div>


        <div style={{paddingTop:'30px', paddingBottom:'20px', textAlign:'center'}}>
          <Button onClick={() => doRegistration() } className='standard-button' size='large' 
            style={{backgroundColor:UI_COLOR_BUTTON, color:'white'}}
          >
            { backendWorking ? <LoadingOutlined /> : <UserAddOutlined /> } Registrarse
          </Button>
        </div>

      </Form>

      <Footer />

      <Modal
        visible={showPrivacy}
        onCancel={()=>setShowPrivacy(false)} 
        okButtonProps={{ style: { display: 'none' } }}
        destroyOnClose={true}
        cancelText={'Cerrar'}
      >
        <h2>Política de Privacidad</h2>
        
          <p>Si elige utilizar nuestro Servicio, acepta la recopilación y el uso de información en relación con esta política. La información personal que recopilamos se utiliza para proporcionar y mejorar el Servicio. No utilizaremos ni compartiremos su información con nadie, excepto como se describe en esta Política de privacidad.</p>
          <p><strong>Recolección de información y uso</strong></p>
          <p>Para una mejor experiencia, mientras utiliza nuestro Servicio, es posible que le solicitemos que nos brinde cierta información de identificación personal, que incluye, entre otros, Nombre, Apellido, Correo electrónico y Documento de identidad. La información que solicitamos será retenida por nosotros y utilizada como se describe en esta política de privacidad.</p>
          <p><strong>Recolección de información y uso</strong></p>
          <p>Para una mejor experiencia, mientras utiliza nuestro Servicio, es posible que le solicitemos que nos brinde cierta información de identificación personal, que incluye, entre otros, Nombre, Apellido, Correo electrónico y Documento de identidad. La información que solicitamos será retenida por nosotros y utilizada como se describe en esta política de privacidad.</p>
          <p>La aplicación utiliza servicios de terceros que pueden recopilar información utilizada para identificarlo.</p>
          <p>Enlaces a las políticas de privacidad de los proveedores de servicios de terceros utilizados en la aplicación:<br/>
            <a href="https://policies.google.com/privacy?hl=es" target="_blank">Servicios de Google</a><br/>
            <a href="https://www.facebook.com/privacy/explanation" target="_blank">Servicios de Facebook</a><br/>
            <a href="https://www.digitalocean.com/legal/privacy-policy/" target="_blank">Servicios de Digital Ocean</a><br/>
            <a href="https://www.smtp.com/policies/acceptable-use-policy/" target="_blank">Servicios de Email.com</a>
          </p>
          <p><strong>Dato de registro</strong></p>
          <p>Deseamos informarle que cada vez que utiliza nuestro Servicio, en caso de un error en la aplicación, recopilamos datos e información (a través de productos de terceros) en su teléfono llamados Datos de registro. Estos Datos de registro pueden incluir información como la dirección del Protocolo de Internet ("IP") de su dispositivo, el nombre del dispositivo, la versión del sistema operativo, la configuración de la aplicación cuando utiliza nuestro Servicio, la hora y la fecha de uso del Servicio y otras estadísticas. Para poder resolver el error que pudiera producirse así mantener un buen servicio.</p>
          <p><strong>Proveedores de servicio</strong></p>
          <p>Podemos emplear a empresas e individuos de terceros debido a las siguientes razones:<br/>
            - Para facilitar nuestro servicio<br/>
            - Para proporcionar el Servicio en nuestro nombre<br/>
            - Para realizar servicios relacionados con el Servicio o<br/>
            - Para ayudarnos a analizar cómo se utiliza nuestro Servicio.
          </p>
          <p>Deseamos informar a los usuarios de este Servicio que estos terceros tienen acceso a su Información personal. El motivo es realizar las tareas que se les asignan en nuestro nombre. Sin embargo, están obligados a no divulgar ni utilizar la información para ningún otro propósito.</p>
          <p><strong>Seguridad</strong></p>
          <p>Valoramos su confianza en proporcionarnos su información personal, por lo tanto, nos esforzamos por utilizar medios comercialmente aceptables para protegerla. Pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro y confiable, y no podemos garantizar su seguridad absoluta.</p>
          <p><strong>¿Cómo eliminar la información personal?</strong></p>
          <p>Al anular su cuenta se eliminará toda la información personal que haya ingresado. Esto lo hace desde "Mi Perfil" en la app móvil.<br />
Este proceso es irreversible y también se eliminan los puntos que tenga acumulados.</p>
          <p><strong>Enlaces a otros sitios</strong></p>
          <p>Este servicio puede contener enlaces a otros sitios. Si hace clic en un enlace de un tercero, será dirigido a ese sitio. Tenga en cuenta que estos sitios externos no son operados por nosotros. Por lo tanto, le recomendamos encarecidamente que revise la Política de privacidad de estos sitios web. no tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.</p>
          <p><strong>Privacidad de los niños</strong></p>
          <p>Estos Servicios no se dirigen a ninguna persona menor de 13 años. No recopilamos a sabiendas información de identificación personal de niños menores de 13 años. En el caso de que descubramos que un niño menor de 13 años nos ha proporcionado información personal, la eliminamos inmediatamente de nuestros servidores. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado información personal, contáctenos para que podamos realizar las acciones necesarias.</p>

        
      </Modal>
    </Main>
  )
}