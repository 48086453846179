import React from 'react'
import { Link } from 'react-router-dom'
import { UI_COLOR_BUTTON, clienStoreId, isMultiStore } from '../config'
import {Row, Col} from 'antd'
import {QrcodeOutlined, ShopOutlined, PoweroffOutlined, UserOutlined, GiftOutlined } from '@ant-design/icons'


const HomeButtons = ({doLogout}) => {
  return (
    <div className='home-buttons'>
      <Row style={{textAlign:'center', paddingLeft:'20px', paddingRight:'20px', paddingBottom:'30px'}}>
      
        <Col span={12}>      
          <Link to="/mi-qr">
            <div><QrcodeOutlined style={{fontSize:'60px', color: UI_COLOR_BUTTON}} /></div>
            <div className='label'>Mi QR</div>
          </Link>
        </Col>

        <Col span={12}>
          { isMultiStore ?
            <Link to="/comercios">
              <div><ShopOutlined style={{fontSize:'60px', color: UI_COLOR_BUTTON}} /></div>
              <div className='label'>Comercios</div>
            </Link>
          :
            <Link to={'/comercio/' + clienStoreId} >
              <div><GiftOutlined style={{fontSize:'60px', color: UI_COLOR_BUTTON}} /></div>
              <div className='label'>Puntos</div>
            </Link>
          }
        </Col>
      </Row>

      <Row style={{textAlign:'center', paddingLeft:'20px', paddingRight:'20px', paddingBottom:'30px' }}>
        <Col span={12}>
          <div onClick={ ()=>doLogout() }>
            <div><PoweroffOutlined style={{fontSize:'60px', color: UI_COLOR_BUTTON}} /></div>
            <div className='label'>Salir</div>
          </div>
        </Col>

        <Col span={12}>
          <Link to="/mi-perfil">
            <div><UserOutlined style={{fontSize:'60px', color: UI_COLOR_BUTTON}} /></div>
            <div className='label'>Mi Perfil</div>
          </Link>
        </Col>

      </Row>

    </div>
  )
}

export default HomeButtons
