import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import firebase from '../lib/Firebase'

import { GeneralContext } from '../lib/context'
import {
  storeLocalData,
  REST_PASSWORD_RECOVERY,
  REST_CUSTOMER_LOGIN,
  getUrlParm,
  getUrlHashtag,
  requestToServer
} from '../lib/helper'
import { UI_COLOR_BUTTON, loginPageLogo } from '../config'

import { notification, Button, Spin, Modal, Input } from 'antd'
import { GoogleOutlined, FacebookFilled  } from '@ant-design/icons'

const LoginForm = ({ doAfterLogin }) => {

  let history = useHistory()
  const [state, dispatch] = useContext(GeneralContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [backendWorking, setBackendWorking] = useState(false)

  const [showPassRecovey, setShowPassRecovey] = useState(false)

  function validateUser ({email, password, socialLoginResponse }) {

    let socialMediaLogin = null
    if ( socialLoginResponse ) {
      if ( socialLoginResponse.additionalUserInfo.providerId === 'google.com' ) {
        socialMediaLogin = {
          media: 'google',
          idToken: socialLoginResponse.credential.idToken
        }
      } else if ( socialLoginResponse.additionalUserInfo.providerId === 'facebook.com') {
        socialMediaLogin = {
          media: 'facebook',
          accessToken: socialLoginResponse.credential.accessToken
        }
      }
    }

    if ( ! backendWorking ) {
      setBackendWorking(true)

      let hashTag = getUrlHashtag() // hashTag = storeId_customerRefererId
      let redirect = getUrlParm('redirect')

      requestToServer({email, password, socialMediaLogin, hashTag }, REST_CUSTOMER_LOGIN).then( result => {
        setBackendWorking(false)

        if (result.s === 'ok') {
          if ( result.notRegistered ) { // when loged by socia login
            dispatch({
              type: 'SET_SOCIAL_LOGIN',
              payload: socialLoginResponse
            })
            let registerPath = '/registro'
            if (redirect) { registerPath += '?redirect=' + redirect }
            if (hashTag) { registerPath += '#' + hashTag } // hashTag = storeId_customerRefererId
            history.push(registerPath)
          } else {
            storeLocalData(result)
            doAfterLogin()
          }
        } else {
          notification.info({
            message: result.m,
            duration: null
          })
        }
      })
    }

  }

  const requestPasswordRecovery = ()=>{
    setShowPassRecovey(false)
    requestToServer({email, type: 'customer'}, REST_PASSWORD_RECOVERY).then( result => {
      if (result) {
        if ( result.s === 'ok' ) {
          notification.success({message:'Recibirá un email con un link de recuperación de contraseña'})
        } else {
          notification.info({message: result.m })
        }
      } else {
        notification.error({message:'Inténtelo de nuevo'})
      }
    })
  }

  return (
    <div>
      <Spin spinning={backendWorking} >
        <div className='login-form'>
          <div style={{textAlign:'center', paddingBottom:'10px'}}><img src={loginPageLogo} className='logo'/></div>

          <div className="floating-input-container" >
            <div className="floating">
              <input  className="floating__input" name="username" type="text" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} value={email} />
              <label  className="floating__label" data-content="Email"></label>
            </div>
          </div>


          <div className="floating-input-container">
            <div className="floating">
              <input  className="floating__input" name="username" type="password" placeholder="Contraseña" onChange={(e) => { setPassword(e.target.value) }} value={password} />
              <label  className="floating__label" data-content="Contraseña"></label>
            </div>
          </div>
            

          <div className='dividers' style={{paddingTop:'10px'}}>
            <Button onClick={ ()=>validateUser({email, password})}  className='standard-button' size='large' style={{ backgroundColor:UI_COLOR_BUTTON, color:'white', width:'100%' }}>
              Login
            </Button>
          </div>


          <div className='dividers' style={{textAlign:'center', padding:'10px 0px'}}>
            <div style={{color:UI_COLOR_BUTTON, textDecoration:'underline'}} className='clickable'
              onClick={()=>{
                setShowPassRecovey(true)
              }}
            >Olvidé mi contraseña</div>
          </div>
          <div className='dividers'>
            <div style={{textAlign:'center'}}>O conéctate usando:</div>
          </div>

          <div className='dividers' >
            <Button className='button-with-icon' size='large' style={{ backgroundColor:UI_COLOR_BUTTON, color:'white', width:'100%'  }}
                onClick={() => {
                  if (!backendWorking) {
                    setBackendWorking(true)

                    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                    firebase.auth().signInWithPopup(googleAuthProvider).then(response => {
                      setBackendWorking(false)
                      if (response) {
                        validateUser({socialLoginResponse : response})
                      }
                    }).catch( e => setBackendWorking(false))
                  }
                }}
              >
                <GoogleOutlined className='google'/> Ingresa con Google
            </Button>
          </div>



          <div className='dividers'>
            <Button className='button-with-icon' size='large' style={{ backgroundColor:UI_COLOR_BUTTON, color:'white', width:'100%' }}
                onClick={() => {
                  if (!backendWorking) {
                    setBackendWorking(true)
                    const fbAuthProvider = new firebase.auth.FacebookAuthProvider();
                    firebase.auth().signInWithPopup(fbAuthProvider).then(response => {
                      setBackendWorking(false)
                      if (response) {
                        validateUser({socialLoginResponse : response})
                      }
                    }).catch( e => setBackendWorking(false))
                  }
                }}
            > <FacebookFilled className='facebook'/> Ingresa con Facebook</Button>
          </div>
          

          <div className='dividers'>
            <Button size='large' style={{ backgroundColor:UI_COLOR_BUTTON, color:'white', width:'100%' }}
              onClick={ ()=>{
                let redirect  = getUrlParm('redirect')
                let hashTag = getUrlHashtag() // hashTag = storeId_customerRefererId
                let regsiterPath = '/registro'
                if (redirect) { regsiterPath += '?redirect=' + redirect }
                if ( hashTag ) { regsiterPath += '#' + hashTag }
                history.push(regsiterPath)
              }} className='standard-button'>
              Registrarse con Email
            </Button>
          </div>

        
        </div>
      </Spin >

      <Modal
        visible={showPassRecovey}
        onCancel={()=>setShowPassRecovey(false)} 
        onOk = {() => requestPasswordRecovery() }
        title='Recuperar Contraseña'
        destroyOnClose={true}
        cancelText={'Cerrar'}
        okText={'ENVIAR'}
      >
        <div style={{textAlign:'center', paddingBottom:'10px'}}>Ingrese su email para recupear la contraseña:</div>
        <div style={{maxWidth:'280px', margin:'0 auto'}}><Input label="Email" value={email} onChange={(e)=>setEmail(e.target.value)} type='email' /></div>
      </Modal>

    </div>
  )
}

export default LoginForm