export const isMultiStore = false
export const platformName = 'eorganic'
export const clienAppName = 'EnjoyOrganic'

export const loginPageLogo = require( './assets/logoEOrganic.jpg' ).default
export const versionName = '3.9.3'

//for one store
export const clienStoreId = 1

// for PROD
export const API_SERVER = 'https://eorganic.api.pretii.lat'

// for DEV
//export const API_SERVER = 'http://192.168.1.23:8080'


export const UI_COLOR_BUTTON = '#00bbff'
export const UI_LOGIN_FOOTER_OBJ = {
  text: 'Acumula puntos en EnjoyOrganic',
  link: null
}


export const enableGA = false

export const APP_ANDROID = null
export const APP_IOS     = null
